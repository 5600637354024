import React from 'react';
import * as THREE from "three";
import { MTLLoader, OBJLoader } from "three-obj-mtl-loader";
import GLTFLoader from 'three-gltf-loader';
import DRACOLoader from 'three-dracoloader';
import FBXLoader from 'three-fbxloader-offical';
// import FBXLoader from 'three-fbx-loader';
import OrbitControls from "three-orbitcontrols";
// import fragment from "../shaders/fragment.glsl";
// import vertex from "../shaders/vertex.glsl";

class Captiva extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        
        };
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.time = 0;
        this.clock = new THREE.Clock();
    }

    componentDidMount() {
        //
        window.addEventListener('resize', this.onWindowResize, true);
        this.onWindowResize();

        //
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;
        this.scene = new THREE.Scene();
        
        // Add Renderer
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.setClearColor("#ffffff");
        this.renderer.setSize(width, height);
        // this.renderer.shadowMap.enabled = true;
        // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.mount.appendChild(this.renderer.domElement);
        
        // Add Camera
        this.camera = new THREE.PerspectiveCamera(70, width / height, 0.01, 10);
        this.camera.position.z = 1;
        this.camera.lookAt(0, 0, 0);

        // Camera Controls
        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.target.set(0, 0, 0);
        
        controls.enableZoom = true;
        controls.enableRotate = true;
        controls.enablePan = false;
        
        // controls.maxPolarAngle = Math.PI / 2.4;
        // controls.minPolarAngle = Math.PI / 7;
        // controls.maxDistance = 200;
        // controls.minDistance = 50;

        // Lights
        var light = new THREE.DirectionalLight(0xffffff, .5);
        light.position.set(-100, 100, 200);
        light.position.multiplyScalar(1.3);
        light.castShadow = true;
        light.shadow.mapSize.width = 1024;
        light.shadow.mapSize.height = 1024;
        var d = 300;
        light.shadow.camera.left = - d;
        light.shadow.camera.right = d;
        light.shadow.camera.top = d;
        light.shadow.camera.bottom = - d;
        light.shadow.camera.far = 1000;
        this.scene.add(light);

        // Add 3D Models
        // this.geometry = new THREE.PlaneBufferGeometry(1, 1, 50, 50);
        // this.material = new THREE.MeshNormalMaterial();

        // this.material = new THREE.ShaderMaterial({
        //     uniforms: {
        //         time: {value:0}
        //     },
        //     side: THREE.DoubleSide,
        //     fragmentShader: `
        //         void main() {
        //             gl_FragColor = vec4(1.,0.,1.0,1.);
        //         }
        //     `,
        //     vertexShader: `
        //         //	Classic Perlin 3D Noise 
        //         //	by Stefan Gustavson
        //         //
        //         vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
        //         vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
        //         vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}
                
        //         float cnoise(vec3 P){
        //             vec3 Pi0 = floor(P); // Integer part for indexing
        //             vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
        //             Pi0 = mod(Pi0, 289.0);
        //             Pi1 = mod(Pi1, 289.0);
        //             vec3 Pf0 = fract(P); // Fractional part for interpolation
        //             vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
        //             vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
        //             vec4 iy = vec4(Pi0.yy, Pi1.yy);
        //             vec4 iz0 = Pi0.zzzz;
        //             vec4 iz1 = Pi1.zzzz;
                
        //             vec4 ixy = permute(permute(ix) + iy);
        //             vec4 ixy0 = permute(ixy + iz0);
        //             vec4 ixy1 = permute(ixy + iz1);
                    
        //             vec4 gx0 = ixy0 / 7.0;
        //             vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
        //             gx0 = fract(gx0);
        //             vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
        //             vec4 sz0 = step(gz0, vec4(0.0));
        //             gx0 -= sz0 * (step(0.0, gx0) - 0.5);
        //             gy0 -= sz0 * (step(0.0, gy0) - 0.5);
                    
        //             vec4 gx1 = ixy1 / 7.0;
        //             vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
        //             gx1 = fract(gx1);
        //             vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
        //             vec4 sz1 = step(gz1, vec4(0.0));
        //             gx1 -= sz1 * (step(0.0, gx1) - 0.5);
        //             gy1 -= sz1 * (step(0.0, gy1) - 0.5);
                
        //             vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
        //             vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
        //             vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
        //             vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
        //             vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
        //             vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
        //             vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
        //             vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);
                    
        //             vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
        //             g000 *= norm0.x;
        //             g010 *= norm0.y;
        //             g100 *= norm0.z;
        //             g110 *= norm0.w;
        //             vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
        //             g001 *= norm1.x;
        //             g011 *= norm1.y;
        //             g101 *= norm1.z;
        //             g111 *= norm1.w;
                
        //             float n000 = dot(g000, Pf0);
        //             float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
        //             float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
        //             float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
        //             float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
        //             float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
        //             float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
        //             float n111 = dot(g111, Pf1);
                    
        //             vec3 fade_xyz = fade(Pf0);
        //             vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
        //             vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
        //             float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
        //             return 2.2 * n_xyz;
        //         }
                
        //         uniform float time;

        //         void main() {
        //             vec3 newposition = position;
        //             float PI = 3.1415925;
        //             //newposition.z += 0.1*sin((newposition.x + 0.25 + time/10)*2.*PI);
        //             newposition.z += 0.1*cnoise(vec3(position.x*4.,position.y*4.,time/2.));
        //             gl_Position = projectionMatrix * modelViewMatrix * vec4(newposition,1.0);
        //         }
        //     `,
        //     wireframe: true,
        // });

        // this.mesh = new THREE.Mesh(this.geometry, this.material);
        // this.scene.add(this.mesh);

        // // Obj
        // var objLoader = new OBJLoader();
        // objLoader.load("./obj/malla.obj",
        //     object => {
        //         var obj = object;

        //         obj.traverse(function(child) {
        //             if (child.isMesh) {
        //                 if (child.name == "malla_Mesh") {

        //                     const mat = new THREE.LineBasicMaterial( {
        //                         color: 0xffffff,
        //                         linewidth: 1,
        //                         linecap: 'round', //ignored by WebGLRenderer
        //                         linejoin:  'round' //ignored by WebGLRenderer
        //                     });

        //                     ////////////////////////////////////////////////////////////////////// 

        //                     var m = new THREE.ShaderMaterial({
        //                         uniforms: {
        //                             time: {value:0}
        //                         },
        //                         side: THREE.DoubleSide,
        //                         fragmentShader: `
        //                             void main() {
        //                                 gl_FragColor = vec4(1.,0.,1.0,1.);
        //                             }
        //                         `,
        //                         vertexShader: `
        //                             //	Classic Perlin 3D Noise 
        //                             //	by Stefan Gustavson
        //                             //
        //                             vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
        //                             vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
        //                             vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}
                                    
        //                             float cnoise(vec3 P){
        //                                 vec3 Pi0 = floor(P); // Integer part for indexing
        //                                 vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
        //                                 Pi0 = mod(Pi0, 289.0);
        //                                 Pi1 = mod(Pi1, 289.0);
        //                                 vec3 Pf0 = fract(P); // Fractional part for interpolation
        //                                 vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
        //                                 vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
        //                                 vec4 iy = vec4(Pi0.yy, Pi1.yy);
        //                                 vec4 iz0 = Pi0.zzzz;
        //                                 vec4 iz1 = Pi1.zzzz;
                                    
        //                                 vec4 ixy = permute(permute(ix) + iy);
        //                                 vec4 ixy0 = permute(ixy + iz0);
        //                                 vec4 ixy1 = permute(ixy + iz1);
                                        
        //                                 vec4 gx0 = ixy0 / 7.0;
        //                                 vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
        //                                 gx0 = fract(gx0);
        //                                 vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
        //                                 vec4 sz0 = step(gz0, vec4(0.0));
        //                                 gx0 -= sz0 * (step(0.0, gx0) - 0.5);
        //                                 gy0 -= sz0 * (step(0.0, gy0) - 0.5);
                                        
        //                                 vec4 gx1 = ixy1 / 7.0;
        //                                 vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
        //                                 gx1 = fract(gx1);
        //                                 vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
        //                                 vec4 sz1 = step(gz1, vec4(0.0));
        //                                 gx1 -= sz1 * (step(0.0, gx1) - 0.5);
        //                                 gy1 -= sz1 * (step(0.0, gy1) - 0.5);
                                    
        //                                 vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
        //                                 vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
        //                                 vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
        //                                 vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
        //                                 vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
        //                                 vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
        //                                 vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
        //                                 vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);
                                        
        //                                 vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
        //                                 g000 *= norm0.x;
        //                                 g010 *= norm0.y;
        //                                 g100 *= norm0.z;
        //                                 g110 *= norm0.w;
        //                                 vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
        //                                 g001 *= norm1.x;
        //                                 g011 *= norm1.y;
        //                                 g101 *= norm1.z;
        //                                 g111 *= norm1.w;
                                    
        //                                 float n000 = dot(g000, Pf0);
        //                                 float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
        //                                 float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
        //                                 float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
        //                                 float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
        //                                 float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
        //                                 float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
        //                                 float n111 = dot(g111, Pf1);
                                        
        //                                 vec3 fade_xyz = fade(Pf0);
        //                                 vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
        //                                 vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
        //                                 float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
        //                                 return 2.2 * n_xyz;
        //                             }
                                    
        //                             uniform float time;
                    
        //                             void main() {
        //                                 vec3 newposition = position;
        //                                 float PI = 3.1415925;
        //                                 //newposition.z += 0.1*sin((newposition.x + 0.25 + time/10)*2.*PI);
        //                                 newposition.z += 0.1*cnoise(vec3(position.x*4.,position.y*4.,time/2.));
        //                                 gl_Position = projectionMatrix * modelViewMatrix * vec4(newposition,1.0);
        //                             }
        //                         `,
        //                         wireframe: true,
        //                     });

        //                     //////////////////////////////////////////////////////////////////////

        //                     child.material = m;
        //                 }
        //             }
        //         });

        //         this.scene.add(obj);
        //     },
        //     xhr => {
        //         console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        //     },
        //     error => {
        //         console.log("An error happened" + error);
        //     }
        // );

        // FBX
        const loader = new FBXLoader();

        loader.load('./fbx/Head_chest.fbx', 
            (object) => {
                
                // this.mixer = new THREE.AnimationMixer(object);
				// const action = this.mixer.clipAction(object.animations[0]);
				// action.play();

                // console.log( object.animations );
                
                this.obj = object;

                this.obj.traverse(function(child) {
                    if (child.isMesh) {                        
                        var mat = new THREE.MeshPhongMaterial({color: 0xb7b7b7, wireframe: false});
                        mat.side = THREE.DoubleSide;
                        child.material = mat;
                    }
                    if (child.name == 'joint2') {
                        // this.joint2 = child;
                    }
                    // console.log(child.name);
                });
                // console.log( object.animations );
                this.obj.scale.set(.005, .005, .005);
                object.position.set(0, -.07, 0);
                this.scene.add(this.obj);
            },
            (xhr) => {
                // console.log(`${( xhr.loaded / xhr.total * 100 )}% loaded`);
            },
            (error) => {
                // console.error('An error happened', error);
            }
        );
        
        // Render Scene
        this.renderScene();
        
        // Start Animation
        this.start();
    }

    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate);
        }
    }
    
    stop = () => {
        cancelAnimationFrame(this.frameId);
    }
    
    animate = () => {
        // Animate Models Here
        const delta = this.clock.getDelta();
        if (this.mixer) this.mixer.update(delta);

        // ReDraw Scene with Camera and Scene Object
        this.time += 0.05;
        this.renderScene();
        this.frameId = window.requestAnimationFrame(this.animate);
        // this.material.uniforms.time.value = this.time;

        // Rotate Joint
        // if (this.joint2) this.joint2.rotation.y = Math.PI / 2;
        // if (this.obj) {
        //     this.obj.traverse(function(child) {
        //         if (child.name == 'joint2') {
        //             child.rotation.y -= .005;
        //         }
        //         // console.log(child.name);
        //     });
        // }
    }
    
    renderScene = () => {
        if (this.renderer) this.renderer.render(this.scene, this.camera);
    }

    componentDidUpdate() {
        
    }

    onWindowResize = () => {
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        if (this.renderer)  {
            this.camera.aspect = this.w / this.h;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.w, this.h);
        }
    }

    onClickButtonVideo = (event) => {
        
    }
    
    render () {
        return(
            <>
                <div style={{ width: "100vw", height: "100vh" }} ref={mount => { this.mount = mount }} />
            </>
        );
    }

}

export default Captiva;